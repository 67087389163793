import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Button from '../components/button'
import { Location } from '@reach/router'
import Layout from '../components/layout'

import ReferralForm from '../components/referral-form'


const ReferralPage = () => {
  return (
    <div>
      <h1>Referral Form</h1>
      <div className="mb-6 sidebarform">
                      <ReferralForm
                        showLabels
                        showPlaceHolders
                      />
                    </div>
    </div>
  );
};

export default ReferralPage;